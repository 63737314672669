import React, { Component } from "react";
import "../App.css";
import firebase from "firebase";
import NavFilter from "../components/NavFilter";
import MovieCard from "../components/MovieCard";
// import SignUp from "./authentication/SignUp";
import request from "superagent";
import debounce from "lodash.debounce";

class MoviesListPage extends Component {
  constructor(props) {
    super(props);
    this.handleYearChange = this.onFilterYearChange.bind(this);
    this.handleRatingChange = this.onFilterRatingChange.bind(this);
    this.handleGenresChange = this.onFilterGenresChange.bind(this);
    this.handleFormSubmit = this.onFormSubmit.bind(this);
    this.removeWatchList = this.removeWatchList.bind(this);
    this.addWatchList = this.addWatchList.bind(this);

    this.state = {
      watchlist: new Set(),
      movies: [],
      isSignedIn: false,
      yearRange: {
        min: 1900,
        max: new Date().getFullYear(),
      },
      ratingRange: {
        min: 0,
        max: 10,
      },
      genres: {
        action: false,
        adventure: false,
        animation: false,
        comedy: false,
        crime: false,
        drama: false,
        fantasy: false,
        family: false,
        history: false,
        horror: false,
        kids: false,
        romance: false,
        sciFi: false,
      },
      lastvotecount: null,
      error: false,
      hasMore: true,
      isLoading: false,
    };
  }

  // Binds our scroll event handler
  onScroll = debounce(() => {
    const {
      getMoreMovies,
      state: { error, isLoading, hasMore },
    } = this;

    // Bails early if:
    // * there's an error
    // * it's already loading
    // * there's nothing left to load
    if (error || isLoading || !hasMore) return;

    // Checks that the page has scrolled to the bottom
    if (
      window.innerHeight + document.documentElement.scrollTop ===
      document.documentElement.offsetHeight
    ) {
      getMoreMovies();
    }
  }, 100);

  uiConfig = {
    signInFlow: "popup",
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD,
    ],
    callbacks: {
      signInSuccess: () => false,
    },
  };

  componentDidMount() {
    firebase.auth().onAuthStateChanged(async (user) => {
      this.setState({ isSignedIn: !!user, currentUser: user });
      console.log("user", user);
      await this.getMovies()
        .then((res) => {
          console.log("test res: ", Object.values(res));
          this.setState({
            movies: this.state.movies.concat(res),
            lastvotecount: res[res.length - 1].item["imdb_votes"],
            isLoading: false,
            hasMore: this.state.movies.length < 4461,
          });
        })
        .catch((err) => {
          this.setState({
            error: err.message,
            isLoading: false,
          });
        });

      console.log(
        "test movies listttt",
        this.state.movies,
        this.state.lastvotecount
      );

      if (user) {
        console.log("testuser");
        await this.getWatchList(user)
          .then((res) => {
            let temp = new Set();
            for (let index in res) {
              temp.add(res[index].id);
            }
            this.setState({ watchlist: temp });
          })
          .catch((err) => console.log(err));
        console.log("test old watch listttt", this.state.watchlist);
      }
    });
    window.addEventListener("scroll", this.onScroll, false);
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
    window.removeEventListener("scroll", this.onScroll, false);
  }

  diffFilter(
    prevYearRange,
    prevRatingRange,
    prevGenres,
    currentGenres,
    currentYearRange,
    currentRatingRange
  ) {
    if (
      prevYearRange == null ||
      prevRatingRange == null ||
      prevGenres == null ||
      prevYearRange.min !== currentYearRange.min ||
      prevYearRange.max !== currentYearRange.max ||
      prevRatingRange.min !== currentRatingRange.min ||
      prevRatingRange.max !== currentRatingRange.max ||
      prevGenres.action !== currentGenres.action ||
      prevGenres.adventure !== currentGenres.adventure ||
      prevGenres.animation !== currentGenres.animation ||
      prevGenres.comedy !== currentGenres.comedy ||
      prevGenres.crime !== currentGenres.crime ||
      prevGenres.drama !== currentGenres.drama ||
      prevGenres.fantasy !== currentGenres.fantasy ||
      prevGenres.family !== currentGenres.family ||
      prevGenres.history !== currentGenres.history ||
      prevGenres.horror !== currentGenres.horror ||
      prevGenres.kids !== currentGenres.kids ||
      prevGenres.romance !== currentGenres.romance ||
      prevGenres.sciFi !== currentGenres.sciFi
    ) {
      return true;
    }
    return false;
  }

  getMovies = async () => {
    //return firebase.auth().onAuthStateChanged(async (user) => {
    this.setState({ isLoading: true });
    let yearlow = this.state.yearRange.min;
    let yearhigh = this.state.yearRange.max;

    let ratinglow = this.state.ratingRange.min;
    let ratinghigh = this.state.ratingRange.max;

    let genresList = Object.entries(this.state.genres);
    let array = [];
    for (let [genre, isChecked] of genresList) {
      if (isChecked) {
        array.push(this.capitalize(genre));
      }
    }
    console.log("test array: ", array);
    let url = `https://us-central1-findmemovie.cloudfunctions.net/app/api/movies?yearlow=${yearlow}&yearhigh=${yearhigh}&ratinglow=${ratinglow}&ratinghigh=${ratinghigh}&genres=${array.toString()}`;

    if (this.state.lastvotecount) {
      url = url + `&lastvotecount=${this.state.lastvotecount}`;
    }
    const response = await fetch(url);
    console.log("test response: ", response);
    const body = await response.json();
    console.log("test body: ", body);
    if (response.status !== 200) throw Error(body.message);
    return body;
    //});
  };

  getMoreMovies = async () => {
    await this.getMovies()
      .then((res) => {
        console.log("test res: ", Object.values(res));
        this.setState({
          movies: this.state.movies.concat(res),
          lastvotecount: res[res.length - 1].item["imdb_votes"],
          isLoading: false,
          hasMore: this.state.movies.length < 4461,
        });
      })
      .catch((err) => {
        this.setState({
          error: err.message,
          isLoading: false,
        });
      });

    console.log(
      "test movies listttt",
      this.state.movies,
      this.state.lastvotecount
    );

    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        console.log("testuser");
        await this.getWatchList(user)
          .then((res) => {
            let temp = new Set();
            for (let index in res) {
              temp.add(res[index].id);
            }
            this.setState({ watchlist: temp });
          })
          .catch((err) => console.log(err));

        console.log("test watch listttt", this.state.watchlist);
      }
    });
  };

  getWatchList = async (user) => {
    return user.getIdToken().then(async (idToken) => {
      const requestOptions = {
        method: "GET",
        headers: { AuthToken: idToken },
      };
      console.log(requestOptions);

      const response = await fetch(
        "https://us-central1-findmemovie.cloudfunctions.net/app/api/watchlist",
        requestOptions
      );
      console.log(response);
      const body = await response.json();
      console.log("testttttt");
      if (response.status !== 200) throw Error(body.message);
      console.log("testttttbodyyyy", body);
      return body;
    });
  };

  addWatchList = async (movie) => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        user.getIdToken().then(async (idToken) => {
          try {
            const requestBody = {
              movie: movie.id,
            };

            const requestOptions = {
              method: "POST",
              headers: { AuthToken: idToken },
              body: JSON.stringify(requestBody),
            };
            const response = await fetch(
              "https://us-central1-findmemovie.cloudfunctions.net/app/api/addwatchlist",
              requestOptions
            );
            if (response.ok) {
              let temp = this.state.watchlist;
              temp.add(movie.id);
              this.setState({
                watchlist: temp,
              });

              if (response.status !== 200)
                throw Error("Error adding to watchlist!");
            }
          } catch (error) {
            console.log(error);
          }
        });
      }
    });
  };

  removeWatchList = async (movie) => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        user.getIdToken().then(async (idToken) => {
          try {
            const requestBody = {
              movie: movie.id,
            };

            const requestOptions = {
              method: "DELETE",
              headers: { AuthToken: idToken },
              body: JSON.stringify(requestBody),
            };
            console.log("check requesoptionssssss");
            console.log(requestOptions);
            const response = await fetch(
              "https://us-central1-findmemovie.cloudfunctions.net/app/api/removewatchlist",
              requestOptions
            );
            if (response.ok) {
              let temp = this.state.watchlist;
              temp.delete(movie.id);
              this.setState({
                watchlist: temp,
              });

              if (response.status !== 200)
                throw Error("Error removing from watchlist!");
            }
          } catch (error) {
            console.log(error);
          }
        });
      }
    });
  };

  componentDidUpdate(prevState, prevProps) {
    if (
      this.diffFilter(
        prevProps.yearRange,
        prevProps.ratingRange,
        prevProps.genres,
        this.state.genres,
        this.state.yearRange,
        this.state.ratingRange
      )
    ) {
      this.getMovies()
        .then((res) => this.setState({ movies: res }))
        .catch((err) => console.log(err));
    }

    console.log(this.state);
  }

  onFilterYearChange(newYearRange) {
    this.setState({
      yearRange: {
        min: newYearRange.min,
        max: newYearRange.max,
      },
    });
    console.log(this.state);
  }

  onFilterRatingChange(newRatingRange) {
    this.setState({
      ratingRange: {
        min: newRatingRange.min,
        max: newRatingRange.max,
      },
    });
    console.log(this.state);
  }

  onFilterGenresChange(e) {
    const val = e.target.checked;
    const name = e.target.name;
    let updatedGenres = Object.assign({}, this.state.genres, { [name]: val });
    this.setState({
      genres: updatedGenres,
    });
  }

  onFormSubmit(e) {
    e.preventDefault();
    console.log("genres", this.state.genres);
  }

  capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  render() {
    return (
      <>
        <NavFilter
          yearRange={this.state.yearRange}
          ratingRange={this.state.ratingRange}
          genres={this.state.genres}
          onFilterYearChange={this.handleYearChange}
          onFilterRatingChange={this.handleRatingChange}
          onFilterGenresChange={this.handleGenresChange}
          onFormSubmit={this.handleFormSubmit}
        />
        <div className="row card-columns" id="page-body">
          {this.state.movies.map((movieObject) => (
            <div key={movieObject.id}>
              <MovieCard
                movie={movieObject}
                watchlist={this.state.watchlist}
                isSignedIn={this.state.isSignedIn}
                onAddWatchList={this.addWatchList}
                onRemoveWatchList={this.removeWatchList}
              />
            </div>
          ))}
        </div>
      </>
    );
  }
}

export default MoviesListPage;
