import "react-input-range/lib/css/index.css";
import React from "react";
import InputRange from "react-input-range";
// import { FormCheckbox } from "shards-react";

class NavFilter extends React.Component {
  constructor(props) {
    super(props);

    this.handleYearChange = this.handleYearChange.bind(this);
    this.handleRatingChange = this.handleRatingChange.bind(this);
    this.handleGenresChange = this.handleGenresChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.state = {
      yearRange: this.props.yearRange,
      ratingRange: this.props.ratingRange,
      genres: this.props.genres,
    };
  }

  handleYearChange(value) {
    this.props.onFilterYearChange(value);
  }

  handleRatingChange(value) {
    this.props.onFilterRatingChange(value);
  }

  handleGenresChange(e) {
    this.props.onFilterGenresChange(e);
  }

  handleFormSubmit(e) {
    this.props.onFormSubmit(e);
  }

  renderGenres() {
    const genresList = [
      { type: "action", name: "Action" },
      { type: "adventure", name: "Adventure" },
      { type: "animation", name: "Animation" },
      { type: "comedy", name: "Comedy" },
      { type: "crime", name: "Crime" },
      { type: "drama", name: "Drama" },
      { type: "fantasy", name: "Fantasy" },
      { type: "family", name: "Family" },
      { type: "history", name: "History" },
      { type: "horror", name: "Horror" },
      { type: "kids", name: "Kids" },
      { type: "romance", name: "Romance" },
      { type: "sciFi", name: "Science-Fiction" },
    ];
    return genresList.map((genre, i) => {
      return (
        <div className="form-check" key={i}>
          <label className="form-check-label">
            <input
              type="checkbox"
              name={genre.type}
              onChange={this.handleGenresChange}
              value={this.state.genres[genre.type]}
              className="form-check-input"
            />

            {genre.name}
          </label>
        </div>
      );
    });
  }

  render() {
    let today = new Date();
    let currentYear = today.getFullYear();
    return (
      <ul className="nav nav-pills bg-dark navbar-dark sticky-top">
        <li className="nav-item">
          <a href="/movies" className="nav-link text-light">
            All
          </a>
        </li>
        {/* <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbar2"
        >
          <span className="navbar-toggler-icon"></span>
        </button> */}
        <li className="nav-item dropdown">
          <a
            className="nav-link dropdown-toggle text-light"
            data-toggle="dropdown"
            href="#"
            role="button"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Year
          </a>
          <div className="dropdown-menu">
            <a className="dropdown-item">
              <form onSubmit={this.handleFormSubmit}>
                <div className="form-group">
                  <InputRange
                    draggableTrack
                    allowSameValues
                    maxValue={currentYear}
                    minValue={1900}
                    onChange={(value) => this.setState({ yearRange: value })}
                    onChangeComplete={(value) => this.handleYearChange(value)}
                    value={this.state.yearRange}
                  />
                </div>
              </form>
            </a>
          </div>
        </li>
        <li className="nav-item dropdown">
          <a
            className="nav-link dropdown-toggle text-light"
            data-toggle="dropdown"
            href="#"
            role="button"
            // aria-haspopup="true"
            // aria-expanded="false"
          >
            Genres
          </a>
          <div className="dropdown-menu">
            <a className="dropdown-item">
              <form onSubmit={this.handleFormSubmit}>
                {this.renderGenres()}
                {/* <div className="form-group">
                      <input type="submit" value="Save" />
                    </div> */}
              </form>
            </a>
          </div>
        </li>

        <li className="nav-item dropdown">
          <a
            className="nav-link dropdown-toggle text-light"
            data-toggle="dropdown"
            href="#"
            role="button"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Rating
          </a>
          <div className="dropdown-menu">
            <a className="dropdown-item">
              <form onSubmit={this.handleFormSubmit}>
                <div className="form-group">
                  <InputRange
                    draggableTrack
                    allowSameValues
                    maxValue={10}
                    minValue={0}
                    onChange={(value) => this.setState({ ratingRange: value })}
                    onChangeComplete={(value) => this.handleRatingChange(value)}
                    value={this.state.ratingRange}
                  />
                </div>
              </form>
            </a>
          </div>
        </li>
      </ul>
    );
  }
}

export default NavFilter;
