import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../App.css";
import StarRatings from "react-star-ratings";
// import SignUp from "./authentication/SignUp";

class MovieCard extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="card" style={{ width: "14rem" }}>
        <div className="poster">
          <img
            className="card-img-top"
            src={this.props.movie.item["imageUrl"]}
            alt="Card image"
            style={{ height: "22rem" }}
          />

          <a href={`/movies/${this.props.movie.id}`}>
            <div className="overlay">
              <div className="row">
                <div className="col">{this.props.movie.item["title"]}</div>
              </div>

              <div className="row">
                <div className="col-sm-5">
                  ({this.props.movie.item["year"]})
                </div>
                <div className="col-sm-7">
                  <div className="badge badge-secondary">
                    {this.props.movie.item["runtime"]}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <StarRatings
                    rating={this.props.movie.item["imdb_rating"]}
                    starDimension="14px"
                    starSpacing="1px"
                    numberOfStars={10}
                    starRatedColor="yellow"
                    name="rating"
                  />
                </div>
              </div>
            </div>
          </a>
          {/* {this.props.isSignedIn ? (
            <div className="row">
              <div className="col">
                {!this.props.watchlist.has(this.props.movie.id) ? (
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    onClick={() => this.props.onAddWatchList(this.props.movie)}
                  >
                    Add to Watchlist
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-danger btn-sm"
                    onClick={() =>
                      this.props.onRemoveWatchList(this.props.movie)
                    }
                  >
                    Remove Watchlist
                  </button>
                )}
              </div>
            </div>
          ) : (
            <div />
          )} */}
        </div>
        {this.props.isSignedIn ? (
          <div className="card-body">
            {/* <Link className="card-title" to={`/movies/${movie.id}`}>
                  <h5>{movie.item["title"]}</h5>
                </Link> */}
            {!this.props.watchlist.has(this.props.movie.id) ? (
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={() => this.props.onAddWatchList(this.props.movie)}
              >
                &#8853; Watchlist
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-danger btn-sm"
                onClick={() => this.props.onRemoveWatchList(this.props.movie)}
              >
                &#8854; Watchlist
              </button>
            )}
          </div>
        ) : (
          <div className="card-body">
            {/* <Link className="card-title" to={`/movies/${movie.id}`}>
                  <h5>{movie.item["title"]}</h5>
                </Link> */}

            <Link to="/login" className="btn btn-primary btn-sm">
              &#8853; Watchlist
            </Link>

            {/* <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={() => this.props.onAddWatchList(this.props.movie)}
              >
                &#8853; Watchlist
              </button> */}
          </div>
        )}
      </div>
    );
  }
}

export default MovieCard;
