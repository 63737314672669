import React from "react";
import NavLink from "./NavLink";
import firebase from "firebase";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";

class NavBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      links: [
        { path: "/", text: "Home", isActive: false },
        { path: "/movies", text: "Movies", isActive: false },
        { path: "/watchlist", text: "Watchlist", isActive: false },
      ],
      isSignedIn: false,
    };
  }

  uiConfig = {
    signInFlow: "popup",
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD,
    ],
    callbacks: {
      signInSuccess: () => false,
    },
  };

  componentDidMount = () => {
    firebase.auth().onAuthStateChanged((user) => {
      this.setState({ isSignedIn: !!user });
      console.log("user", user);
    });
  };

  handleClick(i) {
    const links = this.state.links.slice();
    for (const j in links) {
      links[j].isActive = i == j;
    }
    this.setState({ links: links });
  }

  render() {
    return (
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#collapsibleNavbar"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <a href="/" className="navbar-brand active">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/findmemovie.appspot.com/o/Untitled%20design%20(5).png?alt=media&token=c0719140-a45b-4117-96ad-753ca2e06ca5"
            width="60"
            height="45"
            alt=""
          ></img>
        </a>
        {/* <a href="/" className="navbar-brand active">
          FindMe.Movie
        </a> */}
        <div className="navbar-collapse collapse" id="collapsibleNavbar">
          <ul className="navbar-nav">
            {this.state.links.map((link, i) => (
              <NavLink
                path={link.path}
                text={link.text}
                isActive={link.isActive}
                key={link.path}
                onClick={() => this.handleClick(i)}
              />
            ))}
          </ul>
        </div>

        {!this.state.isSignedIn ? (
          <a
            href="/login"
            className="btn btn-info"
            role="button"
            aria-pressed="true"
          >
            Sign In
          </a>
        ) : (
          <a
            className="btn btn-danger"
            role="button"
            aria-pressed="true"
            onClick={() => firebase.auth().signOut()}
          >
            Sign out
          </a>
        )}
      </nav>
    );
  }
}

export default NavBar;
