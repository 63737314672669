import React, { Component } from "react";
import "../App.css";
import firebase from "firebase";
import NavFilter from "../components/NavFilter";
import MovieCard from "../components/MovieCard";

class WatchListPage extends Component {
  constructor(props) {
    super(props);

    this.handleYearChange = this.onFilterYearChange.bind(this);
    this.handleRatingChange = this.onFilterRatingChange.bind(this);
    this.handleGenresChange = this.onFilterGenresChange.bind(this);
    this.handleFormSubmit = this.onFormSubmit.bind(this);

    this.state = {
      isSignedIn: false,
      watchlist: [],
      watchlistFilted: [],
      watchlistSet: new Set(),
      yearRange: {
        min: 1900,
        max: new Date().getFullYear(),
      },
      ratingRange: {
        min: 0,
        max: 10,
      },
      genres: {
        action: false,
        adventure: false,
        animation: false,
        comedy: false,
        crime: false,
        drama: false,
        fantasy: false,
        family: false,
        history: false,
        horor: false,
        kids: false,
        romance: false,
        sciFi: false,
      },
    };
  }

  uiConfig = {
    signInFlow: "popup",
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD,
    ],
    callbacks: {
      signInSuccess: () => false,
    },
  };

  componentDidMount() {
    console.log("component Did Mount here");
    firebase.auth().onAuthStateChanged(async (user) => {
      this.setState({ isSignedIn: !!user, currentUser: user });

      if (user) {
        await this.getWatchList(user).then((res) => {
          let temp = new Set();
          for (let index in res) {
            temp.add(res[index].id);
          }
          this.setState({
            watchlistSet: temp,
            watchlist: res,
            watchlistFilted: res,
          });
        });
        console.log("test watchlistpage", this.state.watchlist);
      }
    });
  }

  diffFilter(
    prevYearRange,
    prevRatingRange,
    prevGenres,
    currentGenres,
    currentYearRange,
    currentRatingRange
  ) {
    if (
      prevYearRange == null ||
      prevRatingRange == null ||
      prevGenres == null ||
      prevYearRange.min !== currentYearRange.min ||
      prevYearRange.max !== currentYearRange.max ||
      prevRatingRange.min !== currentRatingRange.min ||
      prevRatingRange.max !== currentRatingRange.max ||
      prevGenres.action !== currentGenres.action ||
      prevGenres.adventure !== currentGenres.adventure ||
      prevGenres.animation !== currentGenres.animation ||
      prevGenres.comedy !== currentGenres.comedy ||
      prevGenres.crime !== currentGenres.crime ||
      prevGenres.drama !== currentGenres.drama ||
      prevGenres.fantasy !== currentGenres.fantasy ||
      prevGenres.family !== currentGenres.family ||
      prevGenres.history !== currentGenres.history ||
      prevGenres.horror !== currentGenres.horror ||
      prevGenres.kids !== currentGenres.kids ||
      prevGenres.romance !== currentGenres.romance ||
      prevGenres.sciFi !== currentGenres.sciFi
    ) {
      return true;
    }
    return false;
  }

  componentDidUpdate(prevState, prevProps) {
    console.log("prevProps: ", prevProps);
    console.log("this props: ", this.state);

    if (
      this.diffFilter(
        prevProps.yearRange,
        prevProps.ratingRange,
        prevProps.genres,
        this.state.genres,
        this.state.yearRange,
        this.state.ratingRange
      )
    ) {
      firebase.auth().onAuthStateChanged(async (user) => {
        console.log("user", user);

        if (user) {
          console.log("diffffffff");
          let genresList = Object.entries(this.state.genres);
          let array = [];
          for (let [genre, isChecked] of genresList) {
            if (isChecked) {
              array.push(this.capitalize(genre));
            }
          }
          console.log("test array: ", array, array.length);
          let temp = new Set();
          let tempFilted = [];
          let count = 0;
          for (let i in this.state.watchlist) {
            let isContained = false;
            let movieOb = this.state.watchlist[i];

            console.log("test watchlist[i]: ", movieOb);
            if (array.length == 0) {
              isContained = true;
            } else {
              for (let index in array) {
                if (movieOb.item["genres"].includes(array[index])) {
                  isContained = true;
                  break;
                }
              }
            }
            console.log("test contain: ", isContained);

            if (
              movieOb.item["year"] >= this.state.yearRange.min &&
              movieOb.item["year"] <= this.state.yearRange.max &&
              movieOb.item["imdb_rating"] >= this.state.ratingRange.min &&
              movieOb.item["imdb_rating"] <= this.state.ratingRange.max &&
              isContained
            ) {
              temp.add(movieOb.id);
              tempFilted.push(movieOb);
              console.log("test temp and tempFilted: ", temp, tempFilted);
              count++;
            }
          }
          console.log("test count: ", count);

          this.setState({
            watchlistSet: temp,
            watchlistFilted: tempFilted,
          });
          console.log(
            "test watchlist and watchlistSet: ",
            this.state.watchlist,
            this.state.watchlistSet,
            this.state.watchlistFilted
          );
        }
      });
    }
  }

  getWatchList = async (user) => {
    return user.getIdToken().then(async (idToken) => {
      const requestOptions = {
        method: "GET",
        headers: { AuthToken: idToken },
      };
      console.log(requestOptions);

      let yearlow = this.state.yearRange.min;
      console.log(yearlow);
      let yearhigh = this.state.yearRange.max;

      let ratinglow = this.state.ratingRange.min;
      console.log(ratinglow);
      let ratinghigh = this.state.ratingRange.max;

      let genresList = Object.entries(this.state.genres);
      let array = [];
      for (let [genre, isChecked] of genresList) {
        if (isChecked) {
          array.push(this.capitalize(genre));
        }
      }

      const response = await fetch(
        `https://us-central1-findmemovie.cloudfunctions.net/app/api/watchlist?yearlow=${yearlow}&yearhigh=${yearhigh}&ratinglow=${ratinglow}&ratinghigh=${ratinghigh}&genres=${array.toString()}`,
        requestOptions
      );
      const body = await response.json();
      console.log("test body: ", body);
      if (response.status !== 200) throw Error(body.message);
      return body;
    });
  };

  removeWatchList = async (movie) => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        user.getIdToken().then(async (idToken) => {
          try {
            const requestBody = {
              movie: movie.id,
            };

            const requestOptions = {
              method: "DELETE",
              headers: { AuthToken: idToken },
              body: JSON.stringify(requestBody),
            };
            const response = await fetch(
              "https://us-central1-findmemovie.cloudfunctions.net/app/api/removewatchlist",
              requestOptions
            );
            if (response.ok) {
              let index = this.state.watchlist.indexOf(movie);

              if (index > -1) {
                let array = this.state.watchlist;
                let arrayFilted = this.state.watchlistFilted;
                array.splice(index, 1);
                arrayFilted.splice(index, 1);
                let temp = this.state.watchlistSet;
                temp.delete(movie.id);
                this.setState({
                  watchlist: array,
                  watchlistFilted: arrayFilted,
                  watchlistSet: temp,
                });
              }
              if (response.status !== 200)
                throw Error("Error removing from watchlist!");
            }
          } catch (error) {
            console.log(error);
          }
        });
      }
    });
  };

  onFilterYearChange(newYearRange) {
    this.setState({
      yearRange: {
        min: newYearRange.min,
        max: newYearRange.max,
      },
    });
  }

  onFilterRatingChange(newRatingRange) {
    this.setState({
      ratingRange: {
        min: newRatingRange.min,
        max: newRatingRange.max,
      },
    });
  }

  onFilterGenresChange(e) {
    const val = e.target.checked;
    const name = e.target.name;
    let updatedGenres = Object.assign({}, this.state.genres, { [name]: val });
    this.setState({
      genres: updatedGenres,
    });
  }

  onFormSubmit(e) {
    e.preventDefault();
    console.log("genres", this.state.genres);
  }

  capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  render() {
    return (
      <>
        <NavFilter
          yearRange={this.state.yearRange}
          ratingRange={this.state.ratingRange}
          genres={this.state.genres}
          onFilterYearChange={this.handleYearChange}
          onFilterRatingChange={this.handleRatingChange}
          onFilterGenresChange={this.handleGenresChange}
          onFormSubmit={this.handleFormSubmit}
        />
        <div className="row card-columns" id="page-body">
          {this.state.watchlistFilted.map((movieObject) => (
            <div key={movieObject.id}>
              <MovieCard
                movie={movieObject}
                watchlist={this.state.watchlistSet}
                isSignedIn={this.state.isSignedIn}
                onRemoveWatchList={this.removeWatchList}
              />
            </div>
          ))}
        </div>
      </>
    );
  }
}

export default WatchListPage;
