import React from "react";
import "../App.css";

const HomePage = () => {
  return (
    <div className="home">
      <div className="container-fluid">
        <div className="row justify-content-md-center">
          <img
            className="img-fluid"
            src="https://firebasestorage.googleapis.com/v0/b/findmemovie.appspot.com/o/Untitled%20design%20(6).png?alt=media&token=0323aa02-1574-4c10-a4ea-50d09aa38778"
            alt=""
          ></img>
        </div>

        <div className="row justify-content-md-center">
          <a className="btn btn-warning" href="/movies" role="button">
            Browse Movies
          </a>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
