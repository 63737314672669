import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import HomePage from "./pages/HomePage";
import MoviesListPage from "./pages/MoviesListPage";
import MovieIdPage from "./pages/MovieIdPage";
import WatchListPage from "./pages/WatchListPage";
import NotFoundPage from "./pages/NotFoundPage";
import SignInPage from "./pages/SignInPage";
import NavBar from "./components/NavBar";
import "./App.css";
// import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import firebase from "firebase";

firebase.initializeApp({
  apiKey: "AIzaSyAFgRuabnVvzdsOtgVceJrSTer1LxA43Cs",
  authDomain: "findmemovie.firebaseapp.com",
});

class App extends Component {
  render() {
    return (
      <>
        <Router>
          <div className="App">
            <NavBar />
            {/* <NavFilter
              yearRange={this.state.yearRange}
              onFilterYearChange={this.handleYearChange}
              ratingRange={this.state.ratingRange}
              genres={this.state.genres}
              onFilterRatingChange={this.handleRatingChange}
              onFilterGenresChange={this.handleGenresChange}
              onFormSubmit={this.handleFormSubmit}
            /> */}
            <div>
              <Switch>
                <Route path="/" component={HomePage} exact />
                <Route path="/movies" component={MoviesListPage} exact />
                {/* <Route
                  path="/movies"
                  render={(props) => (
                    <MoviesListPage
                      {...props}
                      yearRange={this.state.yearRange}
                      ratingRange={this.state.ratingRange}
                      genres={this.state.genres}
                      callbackFunction={this.sendData}
                    />
                  )}
                  exact
                /> */}
                {/* <Route
                  path="/movies"
                  render={() => (
                    <MoviesListPage moviesData={this.state.movies} />
                  )}
                  exact
                /> */}
                {/* <Route
                  path="/movies/:id"
                  render={(props) => (
                    <MovieIdPage {...props} moviesData={this.state.movies} />
                  )}
                /> */}
                <Route path="/movies/:id" component={MovieIdPage} />
                <Route path="/watchlist" component={WatchListPage} />
                {/* <Route
                  path="/watchlists"
                  render={(props) => (
                    <WatchlistPage
                      {...props}
                      yearRange={this.state.yearRange}
                      ratingRange={this.state.ratingRange}
                      genres={this.state.genres}
                      callbackFunction={this.sendData}
                    />
                  )}
                  exact
                /> */}
                {/* <Route
                  path="/watchlists"
                  render={() => (
                    <WatchlistPage moviesData={this.state.watchlists} />
                  )}
                /> */}
                <Route path="/login" component={SignInPage} />
                <Route component={NotFoundPage} />
              </Switch>
            </div>
          </div>
        </Router>
      </>
    );
  }
}

export default App;
