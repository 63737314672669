import NotFoundPage from "./NotFoundPage";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../App.css";
import firebase from "firebase";
import StarRatings from "react-star-ratings";

class MovieIdPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      movie: {
        id: "",
        item: {
          actors: "",
          asin: "",
          awards: "",
          box_office: "",
          country: "",
          date_added: "",
          director: [],
          dvd: "",
          genres: [],
          imageUrl: "",
          imdb_id: "",
          imdb_rating: 0,
          imdb_votes: 0,
          language: "",
          link: "",
          metascore: "",
          plot: "",
          production: "",
          rated: "",
          released: "",
          runtime: "",
          title: "",
          type: "",
          website: "",
          writer: [],
          year: 1900,
        },
      },
      isSignedIn: false,
      watchlist: new Set(),
    };
  }

  uiConfig = {
    signInFlow: "popup",
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD,
    ],
    callbacks: {
      signInSuccess: () => false,
    },
  };

  componentWillMount() {
    firebase.auth().onAuthStateChanged(async (user) => {
      this.setState({ isSignedIn: !!user, currentUser: user });
      console.log("user", user);
      await this.getMovieId()
        .then((res) => this.setState({ movie: res }))
        .catch((err) => console.log(err));

      console.log("test moviessss: ", this.state.movie);

      if (user) {
        console.log("testuser");
        await this.getWatchList(user)
          .then((res) => {
            let temp = new Set();
            for (let index in res) {
              temp.add(res[index].id);
            }
            this.setState({ watchlist: temp });
          })
          .catch((err) => console.log(err));
      }
    });
  }

  getMovieId = async () => {
    //return firebase.auth().onAuthStateChanged(async (user) => {
    console.log("test props id: ", this.props.match.params.id);
    const response = await fetch(
      `https://us-central1-findmemovie.cloudfunctions.net/app/api/movies/${this.props.match.params.id}`
    );
    console.log(response);
    const body = await response.json();
    console.log("testttttt");
    if (response.status !== 200) throw Error(body.message);
    console.log(body);
    return body;
    //});
  };

  getWatchList = async (user) => {
    return user.getIdToken().then(async (idToken) => {
      const requestOptions = {
        method: "GET",
        headers: { AuthToken: idToken },
      };
      console.log(requestOptions);

      const response = await fetch(
        "https://us-central1-findmemovie.cloudfunctions.net/app/api/watchlist",
        requestOptions
      );
      console.log(response);
      const body = await response.json();
      console.log("testttttt");
      if (response.status !== 200) throw Error(body.message);
      console.log("testttttbodyyyy", body);
      return body;
    });
  };

  addWatchList = async (movie) => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        user.getIdToken().then(async (idToken) => {
          try {
            const requestBody = {
              movie: movie.id,
            };

            const requestOptions = {
              method: "POST",
              headers: { AuthToken: idToken },
              body: JSON.stringify(requestBody),
            };
            const response = await fetch(
              "https://us-central1-findmemovie.cloudfunctions.net/app/api/addwatchlist",
              requestOptions
            );
            if (response.ok) {
              let temp = this.state.watchlist;
              temp.add(movie.id);
              this.setState({
                watchlist: temp,
              });

              if (response.status !== 200)
                throw Error("Error adding to watchlist!");
            }
          } catch (error) {
            console.log(error);
          }
        });
      }
    });
  };

  removeWatchList = async (movie) => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        user.getIdToken().then(async (idToken) => {
          try {
            const requestBody = {
              movie: movie.id,
            };

            const requestOptions = {
              method: "DELETE",
              headers: { AuthToken: idToken },
              body: JSON.stringify(requestBody),
            };
            console.log("check requesoptionssssss");
            console.log(requestOptions);
            const response = await fetch(
              "https://us-central1-findmemovie.cloudfunctions.net/app/api/removewatchlist",
              requestOptions
            );
            if (response.ok) {
              let temp = this.state.watchlist;
              temp.delete(movie.id);
              this.setState({
                watchlist: temp,
              });

              if (response.status !== 200)
                throw Error("Error removing from watchlist!");
            }
          } catch (error) {
            console.log(error);
          }
        });
      }
    });
  };

  render() {
    console.log(this.state.movie);
    // const id = this.props.match.params.id;
    // console.log(id);
    // const movie = this.state.movies.find((movie) => movie.id === id);
    // console.log(this.state.movies);

    // if (!movie) return <h1 />;

    return (
      <div className="idpage">
        <div id="page-body">
          <div className="container">
            <div className="row">
              <div className="col">
                {/* <div
                  className="card border-dark mb-3"
                  style={{ width: "26rem" }}
                > */}
                <img
                  className="card-img-top"
                  src={this.state.movie.item["imageUrl"]}
                />
                {/* </div> */}
              </div>
              <div className="col-7 text-white">
                <div className="row">
                  <h1>{this.state.movie.item["title"]}</h1>
                  &nbsp;
                  {this.state.isSignedIn ? (
                    <div className="card-body">
                      {/* <Link className="card-title" to={`/movies/${movie.id}`}>
                  <h5>{movie.item["title"]}</h5>
                </Link> */}
                      {!this.state.watchlist.has(this.state.movie.id) ? (
                        <button
                          type="button"
                          className="btn btn-primary btn-sm"
                          onClick={() => this.addWatchList(this.state.movie)}
                        >
                          &#8853; Watchlist
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-danger btn-sm"
                          onClick={() => this.removeWatchList(this.state.movie)}
                        >
                          &#8854; Watchlist
                        </button>
                      )}
                    </div>
                  ) : (
                    <div className="card-body">
                      {/* <Link className="card-title" to={`/movies/${movie.id}`}>
                  <h5>{movie.item["title"]}</h5>
                </Link> */}

                      <Link to="/login" className="btn btn-primary btn-sm">
                        &#8853; Watchlist
                      </Link>

                      {/* <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={() => this.props.onAddWatchList(this.props.movie)}
              >
                &#8853; Watchlist
              </button> */}
                    </div>
                  )}
                </div>
                <p>
                  {this.state.movie.item["genres"].join(" | ")} |{" "}
                  {this.state.movie.item["released"]}
                </p>
                <p>
                  {this.state.movie.item["country"]} |{" "}
                  {this.state.movie.item["language"]}
                  &nbsp; &nbsp;
                  <div className="badge badge-secondary">
                    {this.state.movie.item["runtime"]}
                  </div>
                </p>
                <StarRatings
                  rating={this.state.movie.item["imdb_rating"]}
                  starDimension="15px"
                  starSpacing="2px"
                  numberOfStars={10}
                  starRatedColor="yellow"
                  name="rating"
                />
                &nbsp; {this.state.movie.item["imdb_rating"]} / 10 (
                {this.state.movie.item["imdb_votes"]} votes)
                <p></p>
                <p>{this.state.movie.item["plot"]}</p>
                <p></p>
                <a
                  className="btn btn-warning"
                  href={`${this.state.movie.item["link"]}`}
                  role="button"
                >
                  Watch on Amazon
                </a>
                {/* <h1></h1> */}
                {/* <p>
                  &#9472;&#9472;&#9472;&#9472;&#9472;&#9472;&#9472;&#9472;&#9472;&#9472;&#9472;&#9472;&#9472;&#9472;&#9472;&#9472;&#9472;&#9472;&#9472;&#9472;&#9472;&#9472;&#9472;&#9472;&#9472;&#9472;&#9472;&#9472;&#9472;&#9472;&#9472;&#9472;&#9472;&#9472;
                </p> */}
                {/* <div className="row">
                  <div class="card-deck">
                    {movie.item["actors"].map((actor, i) => (
                      <div class="card" key={i}>

                        <div class="card-body">
                          <h5 class="card-title">{actor}</h5>
                          <p class="card-text">{actor}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MovieIdPage;
